import React from 'react'

import { Link } from 'react-router-dom';

import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import tracking  from './tracking';

const Deal = ({ children, tracking, customClasses, alt, link, serverSide, image, position, onClick }) => {
  useStyles(classes, globalStyles);
  const handleClick = e => {
    trackClick('Banner Promocion home')
    if (onClick) {
      e.preventDefault();
      onClick(link);
    }
    else {
      tracking.click({ alt, position })
    }
  }

  const Component = ({ children, ...rest }) => (
    serverSide 
      ? <Link to={link} {...rest}> {children} </Link>
      : <a href={link} {...rest}> {children} </a>
  );
  
  return (
    <div className={`${globalStyles.flexJustifyCenter} ${customClasses.deal}`}>
      <Component 
        className={`${classes.link} ${customClasses.link}`}  
        onClick={handleClick} 
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={classes.banner}>
          { children }
        </div>
      </Component>
    </div>
  )
}

Deal.defaultProps = {
  customClasses: {
    link: ''
  },
  serverSide: false
};

export default tracking(Deal);