// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._82e11d25346e9c45ea7580f0ed7952e1-scss{width:100%;background-repeat:no-repeat;background-position:center center;background-size:cover;border-radius:10px;z-index:1}._82e11d25346e9c45ea7580f0ed7952e1-scss ._5595c17149367487cbc34f449f83a867-scss{display:flex;height:100%;position:relative;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"link": "_82e11d25346e9c45ea7580f0ed7952e1-scss",
	"banner": "_5595c17149367487cbc34f449f83a867-scss"
};
module.exports = exports;
