import React from 'react';
import googleAnalytics from 'shared/metrics/google-analytics';

export default function tracking(WrapperComponent) {
	return class extends React.Component {

        click(data) {
            try {
                /*
                20220406-DesabilitoGoogleAnalitycsViejo
                googleAnalytics.trackEcommerce('promotionClick', {
                    'promoClick': {
                        'promotions': [{
                            'id': `${data.title}-${data.position}`,
                            'name': data.title,
                            'creative': 'omnibus-home',
                            'position': `promocionCentral-${data.position}`
                        }]
                    }
                });
                */
            } catch(e){ }
        }

		render () {
			const props = {
				tracking: {
					click: this.click
				},
				...this.props
			};
    
			return (
                <WrapperComponent {...props} />
			);
		}
	}
}